import React, { useState } from 'react'
import useWindowDimensions from 'Shared/utils/useWindowDimensions'
import { t } from 'ttag'

import PuxButton from '../PuxButton/PuxButton'

const ContactButton = () => {
  const { width } = useWindowDimensions()
  const baseUrl = t`Redmine.Header.ContactSales.Link`
  const text = t`Redmine.Header.ContactSales`
  const [url, setUrl] = useState(baseUrl)

  function setUrlUTM() {
    if (typeof window !== `undefined`) {
      const urlUTM = window.location.pathname.startsWith(`/`)
        ? window.location.pathname.replace(/\//g, `_`).replace(`_`, ``)
        : window.location.pathname.replace(/\//g, `_`)

      if (window?.location?.pathname === `/`) {
        setUrl(
          `${baseUrl}?utm_source=website&utm_medium=page&utm_campaign=homepage`
        )
      } else {
        setUrl(
          `${baseUrl}?utm_source=website&utm_medium=page&utm_campaign=${urlUTM}`
        )
      }
    }
  }

  if (width > 1499) {
    return (
      <div
        onMouseEnter={setUrlUTM}
        onMouseLeave={setUrlUTM}
        onClick={setUrlUTM}
        onMouseDown={setUrlUTM}
        onTouchStart={setUrlUTM}
      >
        <PuxButton
          buttonType='Button--secondary'
          buttonSize='Button--medium'
          buttonColor='Button--blue'
          buttonLink={{
            url: [url],
            internal: [true],
            text: [text],
          }}
        />
      </div>
    )
  } else {
    return (
      <div
        onMouseEnter={setUrlUTM}
        onMouseLeave={setUrlUTM}
        onClick={setUrlUTM}
        onMouseDown={setUrlUTM}
        onTouchStart={setUrlUTM}
      >
        <a href={url} className={`Header-sales`} />
      </div>
    )
  }
}

export default ContactButton
