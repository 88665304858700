import { FieldValues } from 'react-hook-form'

declare namespace gaconnector {
  function getCookieValues(): {
    fc_channel: string;
    fc_source: string;
    fc_referrer: string;
    fc_landing: string;
  };
}

declare let dataLayer: [{
  event: string,
  country: string
}]

export function getFormData(fieldValues: FieldValues): FormData {
  const data = new FormData()
  data.append('web_referer', window?.location?.href ?? 'unknown web_referer')

  const gaFields = gaconnector?.getCookieValues();
  data.append('fc_channel', gaFields?.fc_channel ?? 'unknown fc_channel')
  data.append('fc_source', gaFields?.fc_source ?? 'unknown fc_source')
  data.append('fc_referrer', gaFields?.fc_referrer ?? 'unknown fc_referrer')
  data.append('fc_landing', gaFields?.fc_landing ?? 'unknown fc_landing')

  const geolocationData = dataLayer?.find(data => data.event === "geolocation_init")?.country
  data.append('web_country_iso', geolocationData ?? 'unknown web_country_iso')

  if (fieldValues?.mauticform) {
    for (const mautickey in fieldValues.mauticform) {
      data.append(mautickey, fieldValues.mauticform[mautickey])
    }
  } else {
    for (const key in fieldValues) {
      data.append(key, fieldValues[key])
    }
  }

  return data
}

export async function sendFormData(
  action: string,
  formData: FormData,
  json: any
) {
  await fetch(action, {
    method: `POST`,
    body: formData,
  })
    .then(async (response) => {
      try {
        json = await response.json()
      } catch (error) {
        // handle invalid response
        if (error instanceof SyntaxError) {
          // Unexpected token < in JSON
          console.log(
            `There was a SyntaxError in form post request (404/500?)`,
            error
          )
        } else {
          console.log(`There was an error in form post request`, error)
        }
      }
      return json
    })
    .catch((error) => {
      // handle no response
      console.log(`There was an error in form post request`, error)
    })
  return json
}

