import './TrainingCenterPage.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import {
  GenericPageProps,
  IEasySoftwareTrainingCenter,
} from 'Shared/queries/page-queries'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

const rootClass = `training-center-page`

const TrainingCenterPage: FunctionComponent<
  GenericPageProps<IEasySoftwareTrainingCenter>
> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const widgets = props.pageContext.widgets

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
      pageProperties={props.pageContext.pageProperties}
      resStrings={props.pageContext.resStrings}
    >
      <div className={rootClass}>
        <WidgetBuilder
          pageContentItemId={pageData.contentItemId}
          widgetBuilderData={widgets?.topWidgetZone}
        />
      </div>
    </Layout>
  )
}

export default TrainingCenterPage

export function Head(
  props: HeadProps<never, IPageContext<IEasySoftwareTrainingCenter>>
) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return (
    <PuxMetaTagsGatsby
      {...metaTags}
      localizedPaths={pageData.localization?.localizations}
    />
  )
}
