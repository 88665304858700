import './CategoryPage.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import {
  GenericPageProps,
  IEasySoftwareCategoryPage,
} from 'Shared/queries/page-queries'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

const CategoryPage: FunctionComponent<
  GenericPageProps<IEasySoftwareCategoryPage>
> = (props) => {
  const pageData = props.pageContext.pageData
  const breadcrumbsData = props.pageContext.breadcrumbs
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const widgets = props.pageContext.widgets
  const sideCardWidgetData = props.pageContext.sideCardWidgets
  const titleClass = [`CategoryPage-title`]
  const editorPath = `categoryWidgets,widgets`

  if (pageData?.categoryPageTitleAlign) {
    titleClass.push(pageData.categoryPageTitleAlign)
  }

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
      pageProperties={props.pageContext.pageProperties}
      resStrings={props.pageContext.resStrings}
    >
      <div className='CategoryPage'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <div className='Container'>
          <PuxContent
            contentModifier='categoryPage'
            sideCards={sideCardWidgetData}
          >
            <div className='CategoryPage-header'>
              <h1 className={titleClass.join(` `)}>
                <EasySoftwareEditorContainer
                  type='input'
                  content={pageData.displayText}
                  wysiwygId={pageData.contentItemId + `-heading`}
                  editorPath={`displayText`}
                  pageContentItemId={pageData.contentItemId}
                />
              </h1>
              <div className='CategoryPage-perex perex'>
                <EasySoftwareEditorContainer
                  pageContentItemId={pageData.contentItemId}
                  content={pageData.categoryPageDescription}
                  editorPath={`categoryPageDescription,html`}
                />
              </div>
            </div>
            <WidgetBuilder
              pageContentItemId={pageData.contentItemId}
              widgetBuilderData={widgets?.categoryWidgets}
              editorPath={editorPath}
            />
          </PuxContent>
        </div>
      </div>
    </Layout>
  )
}

export default CategoryPage

export function Head(
  props: HeadProps<never, IPageContext<IEasySoftwareCategoryPage>>
) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return (
    <PuxMetaTagsGatsby
      {...metaTags}
      localizedPaths={pageData.localization?.localizations}
    />
  )
}
