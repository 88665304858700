import { getFromConfig } from 'Root/Shared/utils/localeURL'
import OnModalInitEn from './MauticOnModalInit/OnModalInitEn'
import OnRouteChangeEn from './MauticOnRouteChange/OnRouteChangeEn'
import OnWebLoadEn from './MauticOnWebLoad/OnWebLoadEn'

export const MauticOnWebLoad = (): void => {
  return OnWebLoadEn()
}

export const MauticOnRouteChange = (): void => {
  return OnRouteChangeEn()
}

export const MauticOnModalInit = (context: HTMLElement): void => {
  const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
  const PROJECT = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`
  const hasOrchardForms = getFromConfig(LOCALE, `hasOrchardForms`, PROJECT)
  if (hasOrchardForms !== "GATSBY_EN_HAS_ORCHARD_FORM" ? hasOrchardForms : process.env.GATSBY_EN_HAS_ORCHARD_FORM === 'true') {
    console.log("Mautic forms MauticOnModalInit disabled")
    return
  } else {
    console.log("Mautic forms MauticOnModalInit enabled")
    return OnModalInitEn(context)
  }
}
