import { getFromConfig } from 'Root/Shared/utils/localeURL'
import OnModalInitCs from './MauticOnModalInit/OnModalInitCs'
import OnModalInitEn from './MauticOnModalInit/OnModalInitEn'
import OnModalInitHu from './MauticOnModalInit/OnModalInitHu'
import OnRouteChangeCs from './MauticOnRouteChange/OnRouteChangeCs'
import OnRouteChangeEn from './MauticOnRouteChange/OnRouteChangeEn'
import OnRouteChangeHu from './MauticOnRouteChange/OnRouteChangeHu'
import OnWebLoadCs from './MauticOnWebLoad/OnWebLoadCs'
import OnWebLoadEn from './MauticOnWebLoad/OnWebLoadEn'
import OnWebLoadHu from './MauticOnWebLoad/OnWebLoadHu'

export const MauticOnWebLoad = (): void => {
  switch (process.env.LOCALE) {
    case `cs`:
      return OnWebLoadCs()
    case `hu`:
      return OnWebLoadHu()
    default:
      return OnWebLoadEn()
  }
}

export const MauticOnRouteChange = (): void => {
  switch (process.env.LOCALE) {
    case `cs`:
      return OnRouteChangeCs()
    case `hu`:
      return OnRouteChangeHu()
    default:
      return OnRouteChangeEn()
  }
}

export const MauticOnModalInit = (context: HTMLElement): void => {
  const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
  const PROJECT = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`
  const hasOrchardForms = getFromConfig(LOCALE, `hasOrchardForms`, PROJECT)

  if (hasOrchardForms !== "GATSBY_EN_HAS_ORCHARD_FORM" ? hasOrchardForms : process.env.GATSBY_EN_HAS_ORCHARD_FORM === 'true') {
    console.log("Mautic forms MauticOnModalInit disabled")
    return
  } else {
    console.log("Mautic forms MauticOnModalInit enabled")
    switch (process.env.LOCALE) {
      case `cs`:
        return OnModalInitCs(context)
      case `hu`:
        return OnModalInitHu(context)
      default:
        return OnModalInitEn(context)
    }
  }
}
