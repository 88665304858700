import React from 'react'

import { FormFieldSubmitType } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import { getClass, replaceResourceStrings } from './formFieldHelper'

const FormFieldSubmitWidget: React.FC<{
  props: FormFieldSubmitType
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  return (
    <div
      className={`FormGroup${getClass(
        props?.metadata?.size,
        widgetProperties
      )} FormGroup--submit`}
    >
      <button
        type='submit'
        className={`Button ${props.buttonType} ${props.buttonColor} ${props.buttonSize}`}
      >
        {replaceResourceStrings(props?.formElementName)}
      </button>
    </div>
  )
}

export default FormFieldSubmitWidget
