import './EasySoftwareSolutions.scss'

import React, { FunctionComponent } from 'react'

import PuxImageBox, { PuxImageBoxType } from '../PuxImageBox/PuxImageBox'
export interface EasySoftwareSolutionsType {
  displayText: string
  solutionsList: {
    contentItems: [PuxImageBoxType]
  }
  solutionsImageSize: string
  solutionsImagePosition: string
  solutionsRowItems: string
  solutionsItemCardCheckbox: boolean
  pageContentItemId: string
  editorPath: string
  contentItemId?: string
}

// const rootClass = `solutions`

const EasySoftwareSolutions: FunctionComponent<EasySoftwareSolutionsType> = (
  props
) => {
  const classesItems: string[] = [`Solutions-items`]

  if (props.solutionsImagePosition) {
    classesItems.push(props.solutionsImagePosition)
  }
  if (props.solutionsImageSize) {
    classesItems.push(props.solutionsImageSize)
  }
  if (props.solutionsRowItems) {
    classesItems.push(props.solutionsRowItems)
  }
  if (props.solutionsItemCardCheckbox) {
    classesItems.push(`is-cards`)
  }

  return (
    <div className='Solutions'>
      <div className={`${classesItems.join(` `)}`}>
        {props.solutionsList &&
          props.solutionsList.contentItems &&
          props.solutionsList.contentItems.map((item) => (
            <PuxImageBox
              key={item.contentItemId}
              {...item}
              pageContentItemId={props.pageContentItemId}
              editorPath={
                props.editorPath +
                `,cid-${props.contentItemId},solutionsList,contentItems`
              }
            />
          ))}
      </div>
    </div>
  )
}
export default EasySoftwareSolutions
