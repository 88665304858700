import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/jetbrains-mono/400.css'
import './src/sass/style.scss'
import 'Shared/sass/style.scss'

import { isEditByUrl, isPreviewByUrl } from 'Shared/utils/edit/editURL'
import {
  MauticOnRouteChange,
  MauticOnWebLoad,
} from 'Shared/mautic/EasyRedmine/ERMauticScripts'
import {
  addEditorCookie,
  resetEditorCookie,
} from 'Shared/utils/edit/useEditorStatus'
import {
  addPreviewCookie,
  resetPreviewCookie,
} from 'Shared/utils/edit/usePreviewStatus'
import { getFromConfig } from 'Shared/utils/localeURL'
import {
  checkHash,
  isBrowser,
  scroller,
} from 'Shared/utils/PuxAnchorLink/puxAnchorUtils'
import { removeAllParamsWhenUTM } from 'Shared/utils/removeUTM'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = () => {
  const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
  const PROJECT = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`
  const hasOrchardForms = getFromConfig(LOCALE, `hasOrchardForms`, PROJECT)


  if (document.querySelector(`.NotFound`)) {
    removeAllParamsWhenUTM(window.location.href)
  }
  if (!window?.dataLayer) {
    window.dataLayer = []
  }
  fetch(process.env.GATSBY_CMS_URL + "/" + PROJECT.replace("GatsbyAI", "").replace("Gatsby", "") + "/puxapi/geolocation").then(response => response.text()).then(data => {
    if (data.length <= 5) {
      window?.dataLayer?.push({
        event: 'geolocation_init',
        country: data
      })
    }
  }).catch(error => error)
  resetEditorCookie()
  resetPreviewCookie()

  const culture = getFromConfig(LOCALE, `isoCode`, PROJECT)

  const script = document.createElement(`script`)
  script.type = `text/javascript`
  script.src = `https://cloud.google.com/ai/gen-app-builder/client?hl=${culture}`
  document.head.appendChild(script)

  if (hasOrchardForms !== "GATSBY_EN_HAS_ORCHARD_FORM" ? hasOrchardForms : process.env.GATSBY_EN_HAS_ORCHARD_FORM === 'true') {
    const scriptGAC = document.createElement(`script`)
    scriptGAC.type = `text/javascript`
    scriptGAC.dataset.cfasync = `false`
    scriptGAC.src = `https://tracker.gaconnector.com/gaconnector.js`
    document.head.appendChild(scriptGAC)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onInitialClientRender = () => {
  const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
  const PROJECT = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`
  const hasOrchardForms = getFromConfig(LOCALE, `hasOrchardForms`, PROJECT)
  if (hasOrchardForms !== "GATSBY_EN_HAS_ORCHARD_FORM" ? hasOrchardForms : process.env.GATSBY_EN_HAS_ORCHARD_FORM === 'true') {
    console.log("Mautic forms OnWebLoad disabled")
  } else {
    console.log("Mautic forms OnWebLoad enabled")
    MauticOnWebLoad()
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onRouteUpdate = ({ location }) => {
  const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
  const PROJECT = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`
  const hasOrchardForms = getFromConfig(LOCALE, `hasOrchardForms`, PROJECT)
  if (hasOrchardForms !== "GATSBY_EN_HAS_ORCHARD_FORM" ? hasOrchardForms : process.env.GATSBY_EN_HAS_ORCHARD_FORM === 'true') {
    console.log("Mautic forms OnRouteChange disabled")
  } else {
    console.log("Mautic forms OnRouteChange enabled")
    MauticOnRouteChange()
  }

  if (isEditByUrl(location.pathname)) {
    addEditorCookie()
  } else {
    resetEditorCookie()
  }

  if (isPreviewByUrl(location.pathname)) {
    addPreviewCookie()
  } else {
    resetPreviewCookie()
  }

  function iOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  }

  if (iOS()) {
    document.querySelector(`#___gatsby`).classList.add(`is-iOS`)
  }

  let windowHash
  const offset = 0
  const duration = 800

  if (isBrowser) {
    window.gatsby_scroll_offset = offset
    window.gatsby_scroll_duration = duration
    windowHash = window.gatsby_scroll_hash
  }

  windowHash ? scroller(windowHash, offset) : checkHash(location, offset)

  if (isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined
  }
}
