import React from 'react';

declare global {
  interface Window {
    puxResStringsInstance: DynamicRS
  }
}

export class DynamicRS {
  resStrings: { key: string; value: string }[] | undefined

  constructor(resStrings: { key: string; value: string }[]) {
    this.resStrings = resStrings
  }

  dt(originalRS: string): string {
    if (!this.resStrings) {
      return originalRS
    }

    const translationObj = this.resStrings.find((obj) => obj.key === originalRS)

    return translationObj?.value || originalRS
  }

  dtx(originalRS: string): React.ReactNode {
    if (!this.resStrings) {
      return originalRS
    }

    const translationObj = this.resStrings.find((obj) => obj.key === originalRS)

    return translationObj?.value ?
      (<span dangerouslySetInnerHTML={{ __html: translationObj?.value }} />) :
      (<span dangerouslySetInnerHTML={{ __html: originalRS }} />)

  }
}

let dynamicRSinstance: DynamicRS | null = null

export function initDynamicRS(resStrings: { key: string; value: string }[]): DynamicRS {
  if (!dynamicRSinstance) {
    dynamicRSinstance = new DynamicRS(resStrings)
  }
}

export function dt(key: string): string {
  if (!dynamicRSinstance?.dt(key)) {
    setTimeout(() => {
      dt(key)
    }, 500)
  }
  return dynamicRSinstance?.dt(key) ?? key
}

export function dtx(key: string): React.ReactNode {
  if (!dynamicRSinstance?.dtx(key)) {
    setTimeout(() => {
      dtx(key)
    }, 500)
  }
  return dynamicRSinstance?.dtx(key) ?? key
}
