import './NewsCategory.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import EasySoftwareBreadcrumbs from 'Root/Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxRepeater from 'Root/Shared/components/PuxRepeater/PuxRepeater'
import { RepeaterContentType } from 'Root/Shared/queries/repeater-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import {
  GenericPageProps,
  IEasySoftwareNewsCategory,
} from 'Shared/queries/page-queries'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

import NewsFilter from './NewsFilter'

const NewsCategory: FunctionComponent<
  GenericPageProps<IEasySoftwareNewsCategory>
> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const repeaterData = props.pageContext.newsRepeater
  const filterData = props.pageContext.newsCategories

  const breadcrumbsData = props.pageContext.breadcrumbs

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
      pageProperties={props.pageContext.pageProperties}
      resStrings={props.pageContext.resStrings}
    >
      <div className='NewsCategory'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <div className='Container'>
          <div className='NewsCategory-title'>
            {pageData.newsCategoryContent?.html ? (
              <EasySoftwareEditorContainer
                wysiwygId={pageData.contentItemId + `-perex`}
                pageContentItemId={pageData.contentItemId}
                content={pageData.newsCategoryContent}
                editorPath={`newsCategoryContent,html`}
              />
            ) : (
              <div className='Wysiwyg Wysiwyg--spaced'>
                <h1>{pageData.displayText}</h1>
              </div>
            )}
          </div>
          {filterData && filterData.length > 0 ? (
            <NewsFilter data={filterData} />
          ) : null}
          {repeaterData ? (
            <div className='NewsCategory-grid'>
              <section className='Section'>
                <div className='Container'>
                  <div className='Section-grid'>
                    <div className='WidgetContainer WidgetContainer-padding WidgetContainer--100 Justify'>
                      <PuxRepeater {...repeaterData} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default NewsCategory

export function Head(
  props: HeadProps<never, IPageContext<IEasySoftwareNewsCategory>>
) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return (
    <PuxMetaTagsGatsby
      {...metaTags}
      localizedPaths={pageData.localization?.localizations}
    />
  )
}
